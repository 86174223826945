.content-container {
  position: relative;
  z-index: 1;
}

.background-container {
  position: relative;

  &.bg1 {
    .map-bg {
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      width: 100%;
      opacity: 0.5;
      scale: 1;
    }
  }

  &.bg2 {
    .map-bg {
      position: absolute;
      top: calc(-300px - ((1430px - 100vw) * 0.5));
      left: calc(450px - ((1430px - 100vw) * 0.3));
      transform: translateX(-50%);
      z-index: -1;
      max-width: 1050px;
      min-width: 1050px;
      max-height: 2000px;
      min-height: 2000px;
      opacity: 0.5;
      background-size: contain;
      background-position: center;
    }

    .map-bg-mobile {
      display: none;
    }
  }
}

.suspense-section {
  position: relative;
  z-index: 2;
}

@media (max-width: 820px) {
  .background-container {
  
    &.bg2 {
      .map-bg {
        display: none;
      }

      .map-bg-mobile {
        display: block;
        position: absolute;
        top: 490px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.5;
        background-size: cover;
        background-position: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .background-container {
  
    &.bg2 {
      .map-bg-mobile {
        top: 490px;
      }
    }
  }
}

@media (max-width: 520px) {
  .background-container {
  
    &.bg2 {
      .map-bg-mobile {
        top: 590px;
      }
    }
  }
}

@media (max-width: 394px) {
  .background-container {
  
    &.bg2 {
      .map-bg-mobile {
        top: 620px;
      }
    }
  }
}

@media (max-width: 394px) {
  .background-container {
  
    &.bg2 {
      .map-bg-mobile {
        top: 640px;
      }
    }
  }
}

@media (max-width: 344px) {
  .background-container {
  
    &.bg2 {
      .map-bg-mobile {
        top: 670px;
      }
    }
  }
}
@primary-color: #154C79;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;