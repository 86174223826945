.floating-nav {
    position: fixed;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    background: rgba(255, 255, 255, 0.4); /* Slightly transparent */
    backdrop-filter: blur(10px); /* Adds a blur effect behind the nav */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    z-index: 1000; /* Keeps the nav on top of other elements */
    transition: background-color 0.3s ease, padding 0.3s ease;
    border-radius: 50px;

    .nav-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        img {
            height: 48px; /* Adjust the logo size */
        }
    }

    .nav-links {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
            margin: 0 15px;

            a {
                text-decoration: none;
                color: #333;
                font-weight: 500;
                transition: color 0.3s ease;

                &:hover {
                    color: #154C79; /* Adjust hover color */
                }
            }
        }
    }

    .cta-button {
        button {
            padding: 10px 20px;
            background-color: #154C79;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #0d3c63; /* Adjust hover background color */
            }
        }
    }
}

@media (max-width: 820px) {
    .floating-nav {
        display: none;
    }
}

@primary-color: #154C79;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;